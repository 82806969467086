exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-bookshelf-tsx": () => import("./../../../src/pages/bookshelf.tsx" /* webpackChunkName: "component---src-pages-bookshelf-tsx" */),
  "component---src-pages-business-card-tsx": () => import("./../../../src/pages/business-card.tsx" /* webpackChunkName: "component---src-pages-business-card-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-timeline-tsx": () => import("./../../../src/pages/timeline.tsx" /* webpackChunkName: "component---src-pages-timeline-tsx" */),
  "component---src-posts-2021-02-23-first-post-md": () => import("./../../../src/posts/2021-02-23-first-post.md" /* webpackChunkName: "component---src-posts-2021-02-23-first-post-md" */),
  "component---src-posts-2021-02-25-about-mongodb-mdx": () => import("./../../../src/posts/2021-02-25-about-mongodb.mdx" /* webpackChunkName: "component---src-posts-2021-02-25-about-mongodb-mdx" */),
  "component---src-posts-2021-02-28-basic-web-hacking-mdx": () => import("./../../../src/posts/2021-02-28-basic-web-hacking.mdx" /* webpackChunkName: "component---src-posts-2021-02-28-basic-web-hacking-mdx" */),
  "component---src-posts-2021-03-14-effective-atomic-design-mdx": () => import("./../../../src/posts/2021-03-14-effective-atomic-design.mdx" /* webpackChunkName: "component---src-posts-2021-03-14-effective-atomic-design-mdx" */),
  "component---src-posts-2021-03-20-deep-dive-into-datetime-mdx": () => import("./../../../src/posts/2021-03-20-deep-dive-into-datetime.mdx" /* webpackChunkName: "component---src-posts-2021-03-20-deep-dive-into-datetime-mdx" */),
  "component---src-posts-2022-08-05-programmers-brain-book-report-mdx": () => import("./../../../src/posts/2022-08-05-programmers-brain-book-report.mdx" /* webpackChunkName: "component---src-posts-2022-08-05-programmers-brain-book-report-mdx" */),
  "component---src-posts-2022-08-16-polymorphic-react-component-mdx": () => import("./../../../src/posts/2022-08-16-polymorphic-react-component.mdx" /* webpackChunkName: "component---src-posts-2022-08-16-polymorphic-react-component-mdx" */),
  "component---src-posts-2022-08-31-the-nature-of-software-development-book-report-mdx": () => import("./../../../src/posts/2022-08-31-the-nature-of-software-development-book-report.mdx" /* webpackChunkName: "component---src-posts-2022-08-31-the-nature-of-software-development-book-report-mdx" */),
  "component---src-posts-2022-09-08-software-master-book-report-mdx": () => import("./../../../src/posts/2022-09-08-software-master-book-report.mdx" /* webpackChunkName: "component---src-posts-2022-09-08-software-master-book-report-mdx" */),
  "component---src-posts-2022-09-18-crafting-esolang-mdx": () => import("./../../../src/posts/2022-09-18-crafting-esolang.mdx" /* webpackChunkName: "component---src-posts-2022-09-18-crafting-esolang-mdx" */),
  "component---src-posts-2023-05-03-spring-multi-module-architecture-mdx": () => import("./../../../src/posts/2023-05-03-spring-multi-module-architecture.mdx" /* webpackChunkName: "component---src-posts-2023-05-03-spring-multi-module-architecture-mdx" */),
  "component---src-posts-2023-07-10-effective-work-mdx": () => import("./../../../src/posts/2023-07-10-effective-work.mdx" /* webpackChunkName: "component---src-posts-2023-07-10-effective-work-mdx" */),
  "component---src-posts-2023-07-17-railway-oriented-programming-mdx": () => import("./../../../src/posts/2023-07-17-railway-oriented-programming.mdx" /* webpackChunkName: "component---src-posts-2023-07-17-railway-oriented-programming-mdx" */),
  "component---src-posts-2023-07-24-functional-data-structure-mdx": () => import("./../../../src/posts/2023-07-24-functional-data-structure.mdx" /* webpackChunkName: "component---src-posts-2023-07-24-functional-data-structure-mdx" */),
  "component---src-posts-2023-11-08-retrace-my-life-mdx": () => import("./../../../src/posts/2023-11-08-retrace-my-life.mdx" /* webpackChunkName: "component---src-posts-2023-11-08-retrace-my-life-mdx" */),
  "component---src-posts-2023-12-05-what-is-beautiful-code-mdx": () => import("./../../../src/posts/2023-12-05-what-is-beautiful-code.mdx" /* webpackChunkName: "component---src-posts-2023-12-05-what-is-beautiful-code-mdx" */),
  "component---src-posts-2023-12-24-developers-learning-and-growth-mdx": () => import("./../../../src/posts/2023-12-24-developers-learning-and-growth.mdx" /* webpackChunkName: "component---src-posts-2023-12-24-developers-learning-and-growth-mdx" */),
  "component---src-posts-2024-01-21-type-driven-development-mdx": () => import("./../../../src/posts/2024-01-21-type-driven-development.mdx" /* webpackChunkName: "component---src-posts-2024-01-21-type-driven-development-mdx" */),
  "component---src-posts-2024-01-23-linear-method-mdx": () => import("./../../../src/posts/2024-01-23-linear-method.mdx" /* webpackChunkName: "component---src-posts-2024-01-23-linear-method-mdx" */),
  "component---src-posts-2024-02-18-render-delegation-react-component-mdx": () => import("./../../../src/posts/2024-02-18-render-delegation-react-component.mdx" /* webpackChunkName: "component---src-posts-2024-02-18-render-delegation-react-component-mdx" */),
  "component---src-posts-2024-03-03-ascii-3-d-renderer-mdx": () => import("./../../../src/posts/2024-03-03-ascii-3d-renderer.mdx" /* webpackChunkName: "component---src-posts-2024-03-03-ascii-3-d-renderer-mdx" */),
  "component---src-posts-2024-03-16-principles-of-debugging-mdx": () => import("./../../../src/posts/2024-03-16-principles-of-debugging.mdx" /* webpackChunkName: "component---src-posts-2024-03-16-principles-of-debugging-mdx" */),
  "component---src-posts-2024-04-14-encrypted-vault-system-mdx": () => import("./../../../src/posts/2024-04-14-encrypted-vault-system.mdx" /* webpackChunkName: "component---src-posts-2024-04-14-encrypted-vault-system-mdx" */),
  "component---src-posts-2024-04-26-living-with-ai-mdx": () => import("./../../../src/posts/2024-04-26-living-with-ai.mdx" /* webpackChunkName: "component---src-posts-2024-04-26-living-with-ai-mdx" */),
  "component---src-posts-2024-05-10-the-shortcut-mdx": () => import("./../../../src/posts/2024-05-10-the-shortcut.mdx" /* webpackChunkName: "component---src-posts-2024-05-10-the-shortcut-mdx" */),
  "component---src-posts-2024-05-17-warp-and-weft-mdx": () => import("./../../../src/posts/2024-05-17-warp-and-weft.mdx" /* webpackChunkName: "component---src-posts-2024-05-17-warp-and-weft-mdx" */),
  "component---src-posts-2024-06-08-log-1-mdx": () => import("./../../../src/posts/2024-06-08-log-1.mdx" /* webpackChunkName: "component---src-posts-2024-06-08-log-1-mdx" */),
  "component---src-posts-2024-06-09-log-2-mdx": () => import("./../../../src/posts/2024-06-09-log-2.mdx" /* webpackChunkName: "component---src-posts-2024-06-09-log-2-mdx" */),
  "component---src-posts-2024-06-10-log-3-mdx": () => import("./../../../src/posts/2024-06-10-log-3.mdx" /* webpackChunkName: "component---src-posts-2024-06-10-log-3-mdx" */),
  "component---src-posts-2024-06-11-log-4-mdx": () => import("./../../../src/posts/2024-06-11-log-4.mdx" /* webpackChunkName: "component---src-posts-2024-06-11-log-4-mdx" */),
  "component---src-posts-2024-06-12-log-5-mdx": () => import("./../../../src/posts/2024-06-12-log-5.mdx" /* webpackChunkName: "component---src-posts-2024-06-12-log-5-mdx" */),
  "component---src-posts-2024-06-13-log-6-mdx": () => import("./../../../src/posts/2024-06-13-log-6.mdx" /* webpackChunkName: "component---src-posts-2024-06-13-log-6-mdx" */),
  "component---src-posts-2024-06-15-log-7-mdx": () => import("./../../../src/posts/2024-06-15-log-7.mdx" /* webpackChunkName: "component---src-posts-2024-06-15-log-7-mdx" */),
  "component---src-posts-2024-06-18-log-8-mdx": () => import("./../../../src/posts/2024-06-18-log-8.mdx" /* webpackChunkName: "component---src-posts-2024-06-18-log-8-mdx" */),
  "component---src-posts-2024-07-09-best-prompt-engineering-lesson-mdx": () => import("./../../../src/posts/2024-07-09-best-prompt-engineering-lesson.mdx" /* webpackChunkName: "component---src-posts-2024-07-09-best-prompt-engineering-lesson-mdx" */),
  "component---src-posts-2024-07-11-log-9-mdx": () => import("./../../../src/posts/2024-07-11-log-9.mdx" /* webpackChunkName: "component---src-posts-2024-07-11-log-9-mdx" */),
  "component---src-posts-2024-07-27-tidy-first-mdx": () => import("./../../../src/posts/2024-07-27-tidy-first.mdx" /* webpackChunkName: "component---src-posts-2024-07-27-tidy-first-mdx" */),
  "component---src-posts-2024-07-29-copybook-mdx": () => import("./../../../src/posts/2024-07-29-copybook.mdx" /* webpackChunkName: "component---src-posts-2024-07-29-copybook-mdx" */),
  "component---src-posts-2024-10-12-the-aesthetics-of-destroying-software-mdx": () => import("./../../../src/posts/2024-10-12-the-aesthetics-of-destroying-software.mdx" /* webpackChunkName: "component---src-posts-2024-10-12-the-aesthetics-of-destroying-software-mdx" */),
  "component---src-posts-2024-10-27-music-recognition-system-mdx": () => import("./../../../src/posts/2024-10-27-music-recognition-system.mdx" /* webpackChunkName: "component---src-posts-2024-10-27-music-recognition-system-mdx" */),
  "component---src-posts-2024-11-10-developers-abstraction-structural-thinking-mdx": () => import("./../../../src/posts/2024-11-10-developers-abstraction-structural-thinking.mdx" /* webpackChunkName: "component---src-posts-2024-11-10-developers-abstraction-structural-thinking-mdx" */),
  "component---src-posts-2025-01-19-using-kotlin-script-mdx": () => import("./../../../src/posts/2025-01-19-using-kotlin-script.mdx" /* webpackChunkName: "component---src-posts-2025-01-19-using-kotlin-script-mdx" */),
  "component---src-posts-2025-02-06-data-oriented-programming-mdx": () => import("./../../../src/posts/2025-02-06-data-oriented-programming.mdx" /* webpackChunkName: "component---src-posts-2025-02-06-data-oriented-programming-mdx" */),
  "component---src-posts-2025-02-19-coding-test-story-mdx": () => import("./../../../src/posts/2025-02-19-coding-test-story.mdx" /* webpackChunkName: "component---src-posts-2025-02-19-coding-test-story-mdx" */),
  "component---src-posts-2025-03-02-react-component-based-thinking-mdx": () => import("./../../../src/posts/2025-03-02-react-component-based-thinking.mdx" /* webpackChunkName: "component---src-posts-2025-03-02-react-component-based-thinking-mdx" */),
  "component---src-posts-2025-03-12-build-large-scale-react-web-apps-mdx": () => import("./../../../src/posts/2025-03-12-build-large-scale-react-web-apps.mdx" /* webpackChunkName: "component---src-posts-2025-03-12-build-large-scale-react-web-apps-mdx" */),
  "component---src-posts-2025-03-30-destruction-oriented-development-with-ai-mdx": () => import("./../../../src/posts/2025-03-30-destruction-oriented-development-with-ai.mdx" /* webpackChunkName: "component---src-posts-2025-03-30-destruction-oriented-development-with-ai-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2021-02-23-first-post-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2021-02-23-first-post.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2021-02-23-first-post-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2021-02-25-about-mongodb-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2021-02-25-about-mongodb.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2021-02-25-about-mongodb-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2021-02-28-basic-web-hacking-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2021-02-28-basic-web-hacking.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2021-02-28-basic-web-hacking-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2021-03-14-effective-atomic-design-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2021-03-14-effective-atomic-design.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2021-03-14-effective-atomic-design-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2021-03-20-deep-dive-into-datetime-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2021-03-20-deep-dive-into-datetime.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2021-03-20-deep-dive-into-datetime-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2022-08-05-programmers-brain-book-report-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2022-08-05-programmers-brain-book-report.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2022-08-05-programmers-brain-book-report-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2022-08-16-polymorphic-react-component-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2022-08-16-polymorphic-react-component.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2022-08-16-polymorphic-react-component-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2022-08-31-the-nature-of-software-development-book-report-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2022-08-31-the-nature-of-software-development-book-report.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2022-08-31-the-nature-of-software-development-book-report-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2022-09-08-software-master-book-report-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2022-09-08-software-master-book-report.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2022-09-08-software-master-book-report-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2022-09-18-crafting-esolang-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2022-09-18-crafting-esolang.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2022-09-18-crafting-esolang-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-05-03-spring-multi-module-architecture-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2023-05-03-spring-multi-module-architecture.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-05-03-spring-multi-module-architecture-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-07-10-effective-work-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2023-07-10-effective-work.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-07-10-effective-work-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-07-17-railway-oriented-programming-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2023-07-17-railway-oriented-programming.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-07-17-railway-oriented-programming-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-07-24-functional-data-structure-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2023-07-24-functional-data-structure.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-07-24-functional-data-structure-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-11-08-retrace-my-life-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2023-11-08-retrace-my-life.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-11-08-retrace-my-life-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-12-05-what-is-beautiful-code-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2023-12-05-what-is-beautiful-code.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-12-05-what-is-beautiful-code-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-12-24-developers-learning-and-growth-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2023-12-24-developers-learning-and-growth.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-12-24-developers-learning-and-growth-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-01-21-type-driven-development-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-01-21-type-driven-development.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-01-21-type-driven-development-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-01-23-linear-method-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-01-23-linear-method.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-01-23-linear-method-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-02-18-render-delegation-react-component-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-02-18-render-delegation-react-component.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-02-18-render-delegation-react-component-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-03-03-ascii-3-d-renderer-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-03-03-ascii-3d-renderer.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-03-03-ascii-3-d-renderer-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-03-16-principles-of-debugging-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-03-16-principles-of-debugging.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-03-16-principles-of-debugging-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-04-14-encrypted-vault-system-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-04-14-encrypted-vault-system.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-04-14-encrypted-vault-system-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-04-26-living-with-ai-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-04-26-living-with-ai.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-04-26-living-with-ai-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-05-10-the-shortcut-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-05-10-the-shortcut.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-05-10-the-shortcut-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-05-17-warp-and-weft-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-05-17-warp-and-weft.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-05-17-warp-and-weft-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-08-log-1-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-06-08-log-1.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-08-log-1-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-09-log-2-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-06-09-log-2.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-09-log-2-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-10-log-3-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-06-10-log-3.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-10-log-3-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-11-log-4-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-06-11-log-4.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-11-log-4-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-12-log-5-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-06-12-log-5.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-12-log-5-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-13-log-6-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-06-13-log-6.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-13-log-6-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-15-log-7-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-06-15-log-7.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-15-log-7-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-18-log-8-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-06-18-log-8.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-06-18-log-8-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-07-09-best-prompt-engineering-lesson-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-07-09-best-prompt-engineering-lesson.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-07-09-best-prompt-engineering-lesson-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-07-11-log-9-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-07-11-log-9.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-07-11-log-9-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-07-27-tidy-first-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-07-27-tidy-first.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-07-27-tidy-first-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-07-29-copybook-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-07-29-copybook.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-07-29-copybook-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-10-12-the-aesthetics-of-destroying-software-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-10-12-the-aesthetics-of-destroying-software.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-10-12-the-aesthetics-of-destroying-software-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-10-27-music-recognition-system-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-10-27-music-recognition-system.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-10-27-music-recognition-system-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-11-10-developers-abstraction-structural-thinking-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2024-11-10-developers-abstraction-structural-thinking.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-11-10-developers-abstraction-structural-thinking-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2025-01-19-using-kotlin-script-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2025-01-19-using-kotlin-script.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2025-01-19-using-kotlin-script-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2025-02-06-data-oriented-programming-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2025-02-06-data-oriented-programming.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2025-02-06-data-oriented-programming-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2025-02-19-coding-test-story-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2025-02-19-coding-test-story.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2025-02-19-coding-test-story-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2025-03-02-react-component-based-thinking-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2025-03-02-react-component-based-thinking.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2025-03-02-react-component-based-thinking-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2025-03-12-build-large-scale-react-web-apps-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2025-03-12-build-large-scale-react-web-apps.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2025-03-12-build-large-scale-react-web-apps-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2025-03-30-destruction-oriented-development-with-ai-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/Users/kciter/project/blog/src/posts/2025-03-30-destruction-oriented-development-with-ai.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2025-03-30-destruction-oriented-development-with-ai-mdx" */)
}

